<template>
  <div class="addAlaForm">
    <el-form ref="formBox" :rules="rules" :model="addForm" :label-width="labelWidth">
      <div v-for="item in formArr" :key="item.key" :style="{width: item.width || '100%'}">
        <el-form-item
          :label="item.label"
          :prop="item.key"
          :style="{ width: item.width || '100%' }"
        >
          <!-- <el-date-picker v-model="addForm.nyr" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker> -->
          <el-input
            v-if="item.type == 'Input' || item.type == 'Textarea'"
            v-model="addForm[item.key]"
            :type="item.type == 'Textarea' ? 'textarea' : 'text'"
            :placeholder="item.placeholder || '请输入' + item.label"
            :disabled="item.disabled || false"
          ></el-input>

          <el-input-number
            v-if="item.type == 'InputNumber'"
            v-model="addForm[item.key]"
            :precision="2"
            :step="0.1"
            style="width: 100%;"
          ></el-input-number>

          <el-select
            v-if="item.type == 'Select'"
            v-model="addForm[item.key]"
            placeholder="请选择"
            :disabled="item.disabled || false"
          >
            <el-option
              v-for="m in item.options"
              :key="m.value"
              :label="m.label"
              :value="m.value"
            >
            </el-option>
          </el-select>

          <el-date-picker
              style="width: 100%"
              v-if="item.type == 'DateTime'"
              v-model="addForm[item.key]"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="date"
              placeholder="选择日期"
          >
          </el-date-picker>

          <!-- 
            active-color="#13ce66" -->
          <el-switch
            v-if="item.type == 'Switch'"
            v-model="addForm[item.key]"
            inactive-color="#ff4949"
            :width="30"
            :active-value="item.activeValue || true"
            :inactive-value="item.inactiveValue || false"
            :inactive-text="item.inactiveText || ''"
            :active-text="item.activeText || ''"
          >
          </el-switch>

          <div style="width: 100%" v-if="item.type == 'Editor'">
            <Editor v-model="addForm.content" />
          </div>

          <div style="width: 100%" v-if="item.type == 'FileUpload'">
            <FileUpload
                ref="myFile"
                :fileRealTimeUp="false"
                :fileListModify="fileListModify"
                :upFileKey="item.key"
                :valueType="item.valueType"
                :fileType="item.fileType"
                :limit="item.limit || 10"
                @getFileListAndForm="getFileListAndForm"
            />
            <!-- @getFileListAndForm="getFileListAndForm" -->
          </div>
        </el-form-item>
      </div>
    </el-form>

    <div slot="footer" class="dialog-footer" v-show="showFlag">
      <el-button type="info" @click="cancel">取消</el-button>
      <el-button type="primary" @click="saveForm('formBox')">确定</el-button>
    </div>
  </div>
</template>
  
  <script>
import FileUpload from "@/components/lxw/FileUpload/index";
import Editor from "@/components/lxw/Editor/index";
import { disable } from "ol/rotationconstraint";
export default {
  components: { FileUpload, Editor },
  props: {
    // 是否显示底部操作按钮
    showFlag: {
      type: Boolean,
      default: true,
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
    formArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fileListModify: {
      type: Array,
      default: () => {
        return [];
      },
    },

    rules: {
      type: Object,
      default: () => {
        return {};
      },
    },
    labelWidth: {
      type: String,
      default: "8vw",
    },
  },
  data() {
    return {
      filesList: [],

      addForm: {},

      fileKey: "",
    };
  },
  watch: {
    record: {
      handler(newVal, oldVal) {
        this.addForm = newVal;
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    console.log("接收到：", this.formArr)

  },
  mounted() {
  },
  methods: {
    getFileListAndForm(obj) {//
      if (obj && obj.fileList) {
        const {fileList} = obj;
        let list = [];
        fileList.forEach((item) => {
          if (
              item.response &&
              item.response.data
          ) {
            list.push(item.response.data);
          }
        });
        this.imgList = list;
      }
    },
    // 确认提交
    saveForm(formName) {
      let param = this.addForm;
      console.log("表单提交：", param)

      if (this.$refs.myFile) {
        let fileLists = this.$refs["myFile"][0].fileList;
        let key = this.$refs["myFile"][0].$options.propsData.upFileKey;
        let fileValueType =
            this.$refs["myFile"][0].$options.propsData.valueType;

        let list = [];
        fileLists.forEach((item) => {
          if (item.response && item.response.data) {
            list.push(item.response.data);
          }
          if (item.fileData && item.name) {
            list.push(item.name);
          }
        });
        if (fileLists.length > 0) {
          if (fileValueType == "string") {
            param[key] = list[0]; // 附件
          } else {
            param[key] = list; // 附件
          }
        }
      }
      // console.log("param--->>>", param);
      // return;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("saveForm", param);
        } else {
          return false;
        }
      });
    },

    // 重置字段
    resetForm() {
      this.$refs["formBox"].resetFields();
    },

    cancel() {
      this.$emit("handleCancel");
    },
  },
};
</script>
  
  <style scoped>
.addAlaForm {
  width: 100%;
  position: relative;
  padding-right: 50px;
  padding-bottom: 6vh;
  box-sizing: border-box;
  max-height: 60vh;
}
.addAlameBox /deep/ .el-dialog__body {
  padding: 3vh 1vw 3vh 0.5vw;
}

.addAlameBox .el-form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 53vh;
  overflow-y: scroll;
}

.addAlameBox ::v-deep .el-date-editor.el-input {
  width: 100% !important;
}
.addAlameBox ::v-deep .el-date-editor.el-input__inner {
  width: 100% !important;
}
.addAlameBox
  .el-form
  ::v-deep
  .el-form-item
  .el-form-item__content
  .el-input
  .el-input__inner::-webkit-input-placeholder {
  width: 100% !important;
  /* color: #f00; */
  font-size: 14px !important;
}
/* 
.addAlameBox /deep/ .el-form .el-form-item {
  width: 33%;
} */

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
  margin-top: 50px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.dialog-footer /deep/ .el-button,
.el-button--info {
  margin-left: 10px !important;
}
.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}
</style>